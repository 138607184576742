import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Shopify",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/shopify.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Shopify`}</h1>
    <p>{`Easy-to-install Live Chat app for Shopify`}</p>
    <hr></hr>
    <p>{`One of the best-in-class E-commerce website building, Shopify runs millions of stores using an easy to use interface. Looking for an easy-to-install Live Chat app for your Shopify ? Using this integration, you can add the Crisp Live chat in a single click on your Shopify!`}</p>
    <p>{`Benefits of using our Live Chat app for Shopify:`}</p>
    <ul>
      <li parentName="ul">{`Add the Crisp Livechat in a single click`}</li>
      <li parentName="ul">{`Manage all your conversations from the Crisp Inbox`}</li>
      <li parentName="ul">{`Automatically adapts to your website language (50+ locales are supported)`}</li>
      <li parentName="ul">{`Customize the Crisp livechat to fit your design`}</li>
      <li parentName="ul">{`Available from the basic plan (For free!)`}</li>
      <li parentName="ul">{`Create conversational experience with your website visitors`}</li>
      <li parentName="ul">{`Create conversational experience with your website visitors`}</li>
      <li parentName="ul">{`Limite checkout abandon rate with instant messages`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      